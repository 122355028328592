// Scrub the raw/human-friendly data in the JSON file next door and prepare it for whatever library needs it
import * as _ from 'lodash';

import dataLog from './log.json';
import DATA_SHAPE from './dataShape.js';
import datesBetween from '../src/utils/datesBetween';


// This "ensures" that no 'undefined' are provided to VictoryChart, which chokes on them
const dataDefaults = {}
// dataFactors.forEach(key => { dataDefaults[key] = dataShape[key].default || null })

const DATE_RANGE = { start: '2018-01-23', end: '2018-01-29' };
const dateArray = datesBetween(DATE_RANGE) // VictoryChart accepts Date objects

export const victoryData = dateArray.map(date => Object.assign({}, dataDefaults, { date: date.toDate() }, dataLog[date.format("YYYY-MM-DD")])) // eslint-disable-line

// export const tagData = dateArray.map( date => Object.assign({}, (dataLog[date.format("YYYY-MM-DD")] || {}).tags, { date: date.format("YYYY-MM-DD") }));

export const tagKeys = Object.keys(dataLog["2018-01-23"].tags)

const objectToArray = (object, keyName) => Object.keys(object).map( key => Object.assign({}, object[key], {[keyName]: key}))
const boolToInt = (obj) => obj === true ? 1 : (obj === false ? 0 : obj); // eslint-disable-line
const ensureNumeric = (obj) => {
  const convertBoolean = boolToInt(obj)
  if ( typeof convertBoolean === 'number') {
    return convertBoolean
  }
  return 0;
}

const ensureNumericValues = (obj) => {
  const result = {};
  Object.keys(obj).forEach( key => {result[key] = ensureNumeric(obj[key])})
  return result;
}

// Ensures that this is not a sparse matrix, because nivo doesn't like that, which is annoying.
// Can set to null (which Victory is okay with), but nivo requires a value for each, so can also default to 0.
const ensureKeysPresent = (obj, keys, defaultValue = null) => {
  const keyObj = {};
  keys.forEach( key => { keyObj[key] = defaultValue });
  return Object.assign({}, keyObj, obj)
}

// Allows profiling paths based on the actual data they contain vs having to pre-define the shape
const getType = (object) => {
  if (object === true || object === false) {
    return 'boolean'
  } else if (typeof object === 'number' || typeof object === 'string') {
    return typeof object
  } else {
    return undefined
  }
}

const inferRange = (value, minMax = [ Infinity, -Infinity ]) => {
  return [
    Math.min(typeof value === 'undefined' ? Infinity : value, minMax[0]),
    Math.max(typeof value === 'undefined' ? -Infinity : value, minMax[1])
  ]
}

const _normalize = (value, range) => { // eslint-disable-line
  if (range.length < 2 || range[0] === range[1]) return 1;
  return (value - range[0]) / (range[1] - range[0])// + range[0]
}

// If you have a nested object, ie { a: { b: { c: 1}}}, this will return {'a.b.c': 1}
const getObjectPaths = (object, prefix) => {
  const result = {}
  Object.keys(object).forEach( key => {
    const downstreamPrefix = prefix ? `${prefix}.${key}` : key
    if (typeof object[key] === 'object') {
      Object.assign(result, getObjectPaths(object[key], downstreamPrefix))
    } else {
      result[downstreamPrefix] = object[key]
    }
  })
  return result;
}

class TagData {
  constructor(rawData) {
    this.dates = Object.keys(rawData) 
    this.keyByDate = {}
    this.keyByTag = {}
    this.dates.forEach( date => {
      const rawTags = (rawData[date] || {}).tags;
      this.keyByDate[date] = rawTags;
      Object.keys(rawTags).forEach( tag => {
        this.keyByTag[tag] = Object.assign({}, this.keyByTag[tag], { [date]: boolToInt(rawTags[tag])})
      })
    })
  }

  getByTag(tagSelection) {
    if (tagSelection) {
      return objectToArray(this.keyByTag, 'tag').filter( el => tagSelection.includes(el.tag));
    }
    return objectToArray(this.keyByTag, 'tag');
  }

  getDates() {
    return this.dates;
  }

  getTagKeys() {
    return Object.keys(this.keyByTag);
  }
}

export const tagData = new TagData(dataLog);

// It's too late in the night to figure out how to co-locate this with TagData in a useful way...so it will be a separate class for the time being
class Data {
  // A little different than the tag data: it flattens out the key structure, applying labels along the way
  constructor(rawData) {
    this.dates = Object.keys(rawData) 
    this.keyByDate = {}
    this.keyByPath = {}
    this.dataShape = Object.assign({}, DATA_SHAPE);
    this.dates.forEach( date => {
      this.keyByDate[date] = getObjectPaths(rawData[date]);
      Object.keys(this.keyByDate[date]).forEach( path => { 
        this.keyByPath[path] = Object.assign({}, this.keyByPath[path], {[date]: this.keyByDate[date][path]});

        // If the data type isn't known yet, infer it from the first value that matches a type (bool/num/str)
        if (!_.get(this.dataShape, `${path}.type`)) { 
          _.set(this.dataShape, `${path}.type`, getType(this.keyByDate[date][path]));
        }

        // Infer the range from numerical data (allows normalization)
        if (_.get(this.dataShape, `${path}.type`) === 'number') {
          _.set(this.dataShape, `${path}.range`, inferRange(this.keyByDate[date][path], _.get(this.dataShape, `${path}.range`)));
        }
      })
    });
  }

  getByDate() {
    return objectToArray(this.keyByDate, 'date');
  }

  getByPath({ paths, type }) {
    return this.getPaths({ paths, type }).map( path => Object.assign({}, {path}, this.keyByPath[path]));
  }

  getDates() {
    return this.dates;
  }

  // Builds a label from the path, stopping if it is ever explicitly provided
  // TODO: memoize on the dataShape itself
  getLabelForPath(path) {
    const splitPath = path.split('.');
    const subPaths = splitPath.map( (_path, index) => splitPath.slice(0, index + 1).join('.')).reverse();
    const result = []
    // If that subpath has an explicit label, use that for the remaining branch of the tree. 
    // Otherwise, just tack on the current key name and keep going up
    subPaths.some( subPath => {
      const label = _.get(this.dataShape, `${subPath}.label`);
      if (label) {
        result.push(label)
        return true
      }
      result.push(_.startCase(subPath.split('.').reverse()[0]))
      return false
    })
    return result.reverse().join(' - ');
    // return _.get(this.dataShape, `${path}.label`, path)
  }

  getPaths({ type, paths } = {}) {
    let result = Object.keys(this.keyByPath)
    if (type) {
      result = result.filter( path => _.get(this.dataShape, `${path}.type`) === type)
    }
    if (paths) {
      result = result.filter( path => paths.includes(path))
    }
    return result;
  }
  
  // Requires [ { id: string|number, data: [{x: 1, y: 2}}, ...], {id: 'another', ...}] 
  // Also have to strip out non-numerical y values. Right now it just converts to 0 which is no good. null breaks Nivo
  transformForNivo({ type, normalize = false, paths } = {}) {
    return this.getPaths({ type, paths }).map( path => 
        ({
          id: path, 
          data: Object.keys(this.keyByPath[path]).map( date => (
            {
              x: date, 
              y: normalize ? _normalize(this.keyByPath[path][date], _.get(this.dataShape, `${path}.range`)) : this.keyByPath[path][date]
            }
          ))
        })
      )
  }

  // Labels the data and converts booleans
  transformForNivoHeatmap({ type, paths } = {}) {
    const keys = this.getDates();
    return this.getByPath({ type, paths }).map( pathData =>
      Object.assign(
        {}, 
        {label: this.getLabelForPath(pathData.path)}, 
        ensureKeysPresent(ensureNumericValues(pathData), keys, 0), 
        {path: pathData.path}
        )
    )
  }
}

export const data = new Data(dataLog);