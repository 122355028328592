import React from 'react';
import { HeatMap, ResponsiveHeatMap } from '@nivo/heatmap';
import { ResponsiveLine } from '@nivo/line'
import { tagData, data } from '../../data/prepareData';
import styles from './data.module.scss';


class DataPage extends React.Component {
  state = {
    pathSelections: data.getPaths(),
    normalize: true,
    domain: 'date'
  };

  handleChangeSettings = (settings) => this.setState(settings);

  renderLine() {
    const { pathSelections, normalize } = this.state;
    return (
      <div className={styles.graphWrapper}>
        <div className={styles.title}>Time-Series Data</div>
        <div className={styles.graph}>
          <ResponsiveLine
            data={data.transformForNivo({ type: 'number', normalize, paths: pathSelections })}
            margin={{
              "top": 50,
              "right": 110,
              "bottom": 50,
              "left": 180
            }}
            xScale={{
              "type": "point"
            }}
            yScale={{
              "type": "linear",
              "stacked": false,
              "min": "auto",
              "max": "auto"
            }}
            axisLeft={{
              legend: normalize ? 'Normalized Value' : 'Value',
              legendPosition: 'middle',
              legendOffset: -40
            }}
            minY="auto"
            maxY="auto"
            stacked={false}
          />
        </div>
      </div>
    )
  }

  renderHeatmap() {
    const { pathSelections } = this.state;
    const pathData = data.transformForNivoHeatmap({ paths: pathSelections, type: 'boolean' })
    const dates = data.getDates();
    console.log(pathData);
    const colorFunc = { domain: (...args) => console.log("colorFunc domain received:", args)}
    return (
      <div className={styles.graphWrapper}>
        <div className={styles.title}>Tags</div>
        <div className={styles.graph}>
          <ResponsiveHeatMap
            data={pathData}
            keys={dates}
            colors='greys'
            indexBy="label"
            axisLeft={{
              orient: "left",
              enable: true,
              legend: '',
              tickSize: 5
            }}
            colors='greys'
            margin={{
              "top": 20,
              "right": 60,
              "bottom": 180,
              "left": 140
            }}
            axisTop={null}
            axisBottom={{
              "orient": "bottom",
              "tickSize": 5,
              "tickPadding": 5,
              "tickRotation": -90,
              "legend": "",
              "legendOffset": 36
            }}
            enableLabels={false}
            padding={5}
          />
        </div>
      </div>
    )
  }

  render() {
    const { domain, normalize, pathSelections } = this.state;
    return (
      <div>
        <div className={styles.navbar}>
          <div className={styles.brand}>The Van Life Data Project</div>
        </div>
        <div className={styles.wrapper}>

          <SettingsPanel
            paths={data.getPaths()}
            pathSelections={pathSelections}
            normalize={normalize}
            domain={domain}
            onChange={this.handleChangeSettings} />
          {this.renderLine()}
          <div className={styles.graphBox}>{this.renderHeatmap()}</div>
        </div>
      </div>
    )
  }
}

export default DataPage;

const SettingsPanel = ({ paths = [], pathSelections = [], domain = 'date', normalize = true, onChange }) => {

  const handleChangePath = (path) => () => {
    if (!onChange) return;
    const index = pathSelections.indexOf(path)
    const newSelections = pathSelections.slice()
    if (index === -1) {
      newSelections.push(path)
    } else {
      newSelections.splice(index, 1)
    }
    onChange({ pathSelections: newSelections });
  }

  return (
    <div className={styles.factorFilter}>
      <div className={styles.factorHeader}><span>Controls</span></div>
      <div className={styles.factors}>
        <button type='button' className={domain === 'date' ? styles.active : undefined} onClick={() => onChange({ domain: 'date' })}>
          By Date
        </button>
        <button type='button' className={domain === 'country' ? styles.active : undefined} onClick={() => onChange({ domain: 'country' })}>
          By Country
        </button>
        <button type='button' className={normalize ? styles.active : undefined} onClick={() => onChange({ normalize: !normalize })}>
          Normalized
        </button>
      </div>
      <div className={styles.factorHeader}><span>Variables</span></div>
      <div className={styles.factors}>
        {paths.map(path =>
          <button key={path} type='button' onClick={handleChangePath(path)} className={pathSelections.includes(path) ? styles.active : undefined}>
            {data.getLabelForPath(path)}
          </button>
        )}
      </div>
    </div>
  )
}