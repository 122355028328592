import moment from 'moment';

const datesBetween = ({ start, end }) => {
  const result = [];
  const _end = moment(end);
  const datePtr = moment(start).startOf('day');
  while (datePtr < _end) {
    result.push(datePtr.clone());
    datePtr.add(1, 'day');
  }
  return result;
}

export default datesBetween;